export const ownPrepAdminMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'dashboard',
    children: [],
  },
  {
    key: 'masters',
    title: 'Masters',
    icon: 'apartment',
    isOpen: false,
    children: [
      {
        key: 'courses',
        title: 'Courses',
        route: '/main/courses',
        icon: 'line',
        children: [],
      },
      {
        key: 'exams',
        title: 'Examinations',
        route: '/main/exams',
        icon: 'line',
        children: [],
      },
      {
        key: 'testTypes',
        title: 'Test Types',
        route: '/main/test/types',
        icon: 'line',
        children: [],
      },
      {
        key: 'testNames',
        title: 'Test Names',
        route: '/main/test/names',
        icon: 'line',
        children: [],
      },
      {
        key: 'subjects',
        title: 'Subjects',
        route: '/main/subjects',
        icon: 'line',
        children: [],
      },
      {
        key: 'chapters',
        title: 'Chapters',
        route: '/main/chapters',
        icon: 'line',
        children: [],
      },
      {
        key: 'concepts',
        title: 'Concepts',
        route: '/main/concepts',
        icon: 'line',
        children: [],
      },
      {
        key: 'difficultyLevels',
        title: 'Difficulty Level',
        route: '/main/difficulty-levels',
        icon: 'line',
        children: [],
      },
      {
        key: 'exerciseNames',
        title: 'Exercise',
        route: '/main/exercise-names',
        icon: 'line',
        children: [],
      },
      {
        key: 'testPackages',
        title: 'Test Package',
        route: '/main/test-packages',
        icon: 'line',
        children: [],
      },
      {
        key: 'branches',
        title: 'Branch',
        route: '/main/branches',
        icon: 'line',
        children: [],
      },
      {
        key: 'seasons',
        title: 'Session',
        route: '/main/seasons',
        icon: 'line',
        children: [],
      },
      {
        key: 'companys',
        title: 'Company',
        route: '/main/company',
        icon: 'line',
        children: [],
      },
      {
        key: 'skills',
        title: 'Skills',
        route: '/main/skills',
        icon: 'line',
        children: [],
      },
      {
        key: 'states',
        title: 'States',
        route: '/main/states',
        icon: 'line',
        children: [],
      },
      {
        key: 'cities',
        title: 'Cities',
        route: '/main/cities',
        icon: 'line',
        children: [],
      },
    ],
  },
  {
    key: 'students',
    title: 'Students',
    route: '/main/students',
    icon: 'user-add',
    children: [],
  },
  {
    key: 'faculties',
    title: 'Faculties',
    route: '/main/faculties',
    icon: 'usergroup-add',
    children: [],
  },
  {
    key: 'institutes',
    title: 'Institute',
    route: '/main/institutes',
    icon: 'home',
    children: [],
  },
  {
    key: 'colleges',
    title: 'Colleges',
    route: '/main/colleges',
    icon: 'home',
    children: [],
  },
  {
    key: 'questions',
    title: 'Question Bank',
    route: '/main/questions',
    icon: 'question',
    children: [],
  },
  {
    key: 'practiceExcercises',
    title: 'Practice Exercise',
    route: '/main/practice-exercises',
    icon: 'form',
    children: [],
  },
  {
    key: 'practiceTests',
    title: 'Practice Tests',
    route: '/main/practice-tests',
    icon: 'form',
    children: [],
  },
  {
    key: 'testSeries',
    title: 'Test Series',
    route: '/main/test-series',
    icon: 'split-cells',
    children: [],
  },
  {
    key: 'watchLearns',
    title: 'Watch & Learn',
    route: '/main/watch-learn',
    icon: 'video-camera',
    children: [],
  },
  {
    key: 'studyMaterials',
    title: 'Study Material',
    route: '/main/study-material',
    icon: 'book',
    children: [],
  },
  {
    key: 'payment',
    title: 'Payment',
    route: '/main/payment',
    icon: 'money-collect',
    children: [],
  },
  {
    key: 'pushNotifications',
    title: 'Push Notification',
    route: '/main/push-notification',
    icon: 'bell',
    children: [],
  },
  {
    key: 'freeCourse',
    title: 'Assign Free Course ',
    route: '/main/free-course/student',
    icon: 'bell',
    children: [],
  },
  {
    key: 'reports',
    title: 'Reports',
    icon: 'audit',
    isOpen: false,
    children: [
      {
        key: 'seriesTestReport',
        title: 'Series Test',
        route: '/main/reports/series-test',
        icon: 'line',
        children: [],
      },
      {
        key: 'practiceTestReport',
        title: 'Practice Test',
        route: '/main/reports/practice-test',
        icon: 'line',
        children: [],
      },
      {
        key: 'practiceExerciseReport',
        title: 'Practice Exercise',
        route: '/main/reports/practice-exercise',
        icon: 'line',
        children: [],
      }
    ]
  },
  {
    key: 'liveClasses',
    title: 'Live Classes',
    route: '/main/live-classes',
    icon: 'video-camera-add',
    children: [],
  },
  {
    key: 'companyHiring',
    title: 'Company Listing',
    route: '/main/company-hiring',
    icon: 'video-camera-add',
    children: [],
  },
];

export const ownPrepCompanyMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'dashboard',
    children: [],
  },
  {
    key: 'Profile',
    title: 'Profile',
    route: '/main/company-profile',
    icon: 'user-add',
    children: [],
  }
];

// export const ownPrepInstituteMenu = [
//     {
//       key: 'colleges',
//       title: 'Colleges',
//       route: '/main/colleges',
//       icon: 'home',
//       children: [],
//     },
//     {
//       key: 'students',
//       title: 'Students',
//       route: '/main/students',
//       icon: 'user-add',
//       children: [],
//     }
// ];
