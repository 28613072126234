import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ownPrepAdminMenu, ownPrepCompanyMenu } from '../data/menu.data';
import { HttpRequestService } from './http-request.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menu: Subject<any[]> = new Subject<any[]>();
  defaulAllowed = ['dashboard'];
  constructor(private httpRequestService: HttpRequestService) { }
  public async generateMenu(user: any): Promise<any> {
    if (user.role === 'admin') {
      const collegeIndex = ownPrepAdminMenu.findIndex(x => x.key === 'colleges');
      const menuTemp = [...ownPrepAdminMenu];
      menuTemp.splice(collegeIndex, 1);
      this.menu.next(menuTemp);
    } else if (user.role === 'companyAdmin') {
      const menuTemp = [...ownPrepCompanyMenu];
      this.menu.next(menuTemp);
    } else {
      const {
        data: { modules },
      } = await this.getPermissionsByUser(user._id);
      const moduleByKey = modules.reduce(
        (acc: any, curr: any) => (
          (acc[curr.frontendAccessKey] = curr.permittedOperations), acc
        ),
        {}
      );
      localStorage.setItem('moduleByKey', btoa(JSON.stringify(moduleByKey)));
      const updatedMenu = this.filterMenuByPermission(ownPrepAdminMenu, [
        ...modules.map((x: any) => x.frontendAccessKey),
        ...this.defaulAllowed,
      ]);
      this.menu.next(updatedMenu);
    }
    // switch (user.role) {
    //   case 'admin':
    //     this.menu.next(ownPrepAdminMenu);
    //     break;

    //   case 'instituteAdmin':
    //     const institutePermission = await this.getPermissionsByUser(user._id);
    //     const instituteModuleByKey = institutePermission.data.modules.reduce(
    //       (acc: any, curr: any) => (
    //         (acc[curr.frontendAccessKey] = curr.permittedOperations), acc
    //       ),
    //       {}
    //     );
    //     localStorage.setItem(
    //       'moduleByKey',
    //       btoa(JSON.stringify(instituteModuleByKey))
    //     );
    //     const instituteUpdatedMenu = this.filterMenuByPermission(
    //       ownPrepInstituteMenu,
    //       [
    //         ...institutePermission.data.modules.map((x: any) => x.frontendAccessKey),
    //         ...this.defaulAllowed,
    //       ]
    //     );
    //     this.menu.next(instituteUpdatedMenu);

    //     break;

    //   case 'faculty':
    //     const {
    //       data: { modules },
    //     } = await this.getPermissionsByUser(user._id);
    //     const moduleByKey = modules.reduce(
    //       (acc: any, curr: any) => (
    //         (acc[curr.frontendAccessKey] = curr.permittedOperations), acc
    //       ),
    //       {}
    //     );
    //     localStorage.setItem('moduleByKey', btoa(JSON.stringify(moduleByKey)));
    //     const updatedMenu = this.filterMenuByPermission(ownPrepAdminMenu, [
    //       ...modules.map((x: any) => x.frontendAccessKey),
    //       ...this.defaulAllowed,
    //     ]);
    //     this.menu.next(updatedMenu);
    //     break;
    //   case 'default':
    //     break;
    // }
  }

  async getPermissionsByUser(userId?: any): Promise<any> {
    return this.httpRequestService
      .request('get', `permissions/${userId}`)
      .toPromise();
  }

  filterMenuByPermission(menu: any[], permittedModules: string[]): any {
    const finnalMenu = [...menu];
    finnalMenu.forEach((data: any) => {
      data.children = data.children.filter((x: any) =>
        permittedModules.includes(x.key)
      );
    });
    return finnalMenu.filter(
      (data: any) => permittedModules.includes(data.key) || data.children.length
    );
  }
}
